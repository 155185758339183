.main-screen{
    width: 100%;
    height: 90vh;
    background: #3c4043;
}
.footer{
    height: 10vh;
}
.wrapper{
    width: 100%;
}
@media only screen and (max-width: 900px) {
.footer{
    height: 10vh;
    position: fixed;
    width: 100%;
    top: 0;
}
}