.App {
  display: flex;
  width: 100vw;
  height: 100vh;
}
input{
  margin-bottom: 10px !important;
}
.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  margin: 0 auto !important;
  display: block !important;
  font-weight: 500;
    font-size: 18px;
    width: 100px;
}
.Join-form{
  margin-top: 90px;
}
.Join-form label{
  font-weight: 500;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  height: 44px;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #a5a5a5;
  -webkit-appearance: none;
  box-shadow: 2px 2px 9px #ccc;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 10px;
  margin-bottom: 20px;
}
.form-box {
  border: 1px solid #ccc;
  padding: 30px 15px;
  border-radius: 5px;
  box-shadow: 5px 4px 5px #ccc;
  background: #eee;
}
.form-group{
  padding: 0px 20px !important;
}
.showError{    
  border-color: #2173df !important;    
  color: #2173df !important;    
  margin-bottom: 15px;    
  box-shadow: 0px 0px 10px #2173df;
}
.main-logo{
  width: 260px!important; 
} 
.logo-padd {
  padding: 10px 0px !important;
  border-bottom: 1px solid #d9d9d9;
  box-shadow: 0px 0px 5px #ccc;
}
.top-bar{
  background-color: #02028b;
  padding: 5px;
}
.top-bar p{
  text-align: right;
  color: #fff;
  margin-bottom: 0px !important;
}
.btn-primary {
  margin-top: 25px !important;
}
.meetingLink h6{
  color: #fff;
  padding-left: 30px;
  padding-top: 7px;
  position: absolute;
  cursor: pointer;
}
/* .meetingLinkCss{
  color: red !important;
} */
.fadeTextHide{
  display: none;
}
.fadeText{
  display: block;
  color: #fff !important;
  opacity: 0;
  animation: fade 3s linear;
  /*position: absolute;
  right: 12%;
  bottom: 56px;*/
}

@keyframes fade {
  0%{ opacity: 1 }
  25% { opacity: 1}
  50% { opacity: 0}
 100% { opacity: 0}
}
@media(max-width:900px){
  .meetingLink h6{
    color: #fff;
    padding-left: 20%;
    padding-top: 7px;
    position: inherit !important;
    cursor: pointer;
  }
}
.leaveBtn {
  position: absolute;
  right: 30px;
  top: 19px;
  padding: 1px 10px !important;
}

.meetingLink h6 {
  color: #fff;
  left: 0;
  padding-top: 7px;
  position: absolute;
  top: 18px;
  cursor: pointer;
}
/* .meetingLinkCss{
  color: red !important;
} */
.fadeTextHide{
  display: none;
}

.fadeText {
  display: block;
  color: rgb(230, 84, 84) !important;
  opacity: 0;
  animation: fade 3s linear;
  position: absolute;
  left: 10%;
  top: 22px;
}

@keyframes fade {
  0%{ opacity: 1 }
  25% { opacity: 1}
  50% { opacity: 1}
 100% { opacity: 0}
}

.ciclegraph {
  position: relative;
  width: 245px;
  height: 245px;
  margin: 0 auto;
}
 
.ciclegraph:before {
  content: "";
  position: absolute;
  top: 35px;
  left: 35px;
  /* box-shadow: 0px 2px 8px #000; */
  width: calc(100% - 17px * 2);
  height: calc(100% - 17px * 2);
  background: #f7eadb;
  border-radius: 50%;
    background-image: url("./images/table.png");
   background-size: cover;
}
 

.ciclegraph .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin: calc(-70px / 2);
  /* background: #ffffff; */
  border-radius: 50%;
  /* border: 1px solid #00008c; */
  padding: 3px;
  /* cursor: pointer; */
}
.circle img {
  width:100px;
  height: 100px;
  border: 0px solid #00008c;
  /* transform: rotate(60deg); */
  padding: 3px;
  /* border-radius: 50px; */
}
 
.transImg:hover{
  transform: scale(1.2);
  cursor: pointer;
  box-shadow: 0 30px 30px 0 rgb(0 0 0 / 13%);
  border-radius: 50px;
  transition: 0.5s;
}
.titleNameJoin2 {
  top: -2px;
  left: 2px;
  position: absolute;
  /* background: #ffffff; */
  font-size: 12px !important;
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (max-width: 900px) {
  .ciclegraph {
    position: relative;
    width: 245px;
    height: 245px;
    margin: 0 auto;
    margin-top: 20px;
}
  
  .ciclegraph:before {
    content: "";
    position: absolute;
    top: 35px;
    left: 11px;
    border: 2px dotted #00008c;
    border: 0px solid #636363;
    background: linear-gradient(45deg, #90b4ea, #dbd3c8);
    /* box-shadow: 0px 2px 9px #000000; */
    width: calc(100% - 17px * 2);
    height: calc(100% - 17px * 2);
    background-image: url("./images/table.png");
    border-radius: 50%;
    background-size: cover;
}
}
.errorMsg {
  color: red;
  width: 100%;
  float: left;
  font-size: 12px;
}
.badge {
  display: inline-block;
  padding: 3px !important;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.tableBg {
  /* background: #fbfbfbb5; */
  padding: 5px 0px 10px 0px;
  border-radius: 50%;
}
.tableBg2{
  /* background: #fbfbfbb5; */
    padding: 0px 0px 8px 12px;
    border-radius: 50%;
    /* width: 300px; */
    /* height: 300px; */
    margin-top: 30px;
}

.inputBoxCreate {
  z-index: 999;
  height: 27px !important;
  position: absolute;
  width: 130px !important;
  background: #fff;
  font-size: 14px;
  padding-left: 7px;
  right: -5px;
  border: 1px solid #00008c;
  bottom: 43%;
  box-shadow: 2px 2px 5px #272626;
  border-radius: 7px;
  outline: 0;
}
.tableIcon-img1 {
  width: 24px;
  position: absolute;
  bottom: 46%;
  right: -34px;
  z-index: 999;
  cursor: pointer;
}
/* .tableIcon{
  width: 30px;
} */
.joinTableInput{
  width: 100% !important;
    float: left;
    margin: 0px;
    height: 35px;
}
.joinTableImg{
    float: left;
    padding-left: 10px;
    padding-top: 1px;
    width: 40px;
    cursor: pointer;
}
.joinLockImg {
  float: none;
  padding-left: 10px;
  padding-top: 0px;
  width: 40px;
  margin-left: 24px;
  cursor: pointer;
}
.popover{
  width: 220px !important;
}
.popover-body {
  padding: 9px 5px !important;
  color: #212529;
}
.chairAngle0{
  transform: rotate(60deg); 
}
.chairAngle0.empChairImg:hover{
  transform: rotate(60deg) scale(1.2);
  transition: 0.4s;  
  margin-left: 5px;
}
.chairAngle1{
  transform: rotate(120deg);
}
.chairAngle1.empChairImg:hover{
  transform: rotate(120deg) scale(1.2);
  transition: 0.4s;
  margin-left: 3px;
  margin-top: 3px;
}
.chairAngle2{
  transform: rotate(180deg);
}
.chairAngle2.empChairImg:hover{
  transform: rotate(180deg) scale(1.2);
    transition: 0.4s;
    margin-top: 5px;
}
.chairAngle3{
  transform: rotate(240deg);
}
.chairAngle3.empChairImg:hover{
  transform: rotate(240deg) scale(1.2);
  transition: 0.4s;  
  margin-left: -5px;
}
.chairAngle4{
  transform: rotate(300deg);
}
.chairAngle4.empChairImg:hover{
  transform: rotate(300deg) scale(1.2);
  transition: 0.4s;  
  margin-left: -3px;
  margin-top: -3px;
}
.chairAngle5{
  transform: rotate(360deg);
}
.chairAngle5.empChairImg:hover{
  transform: rotate(360deg) scale(1.2);
  transition: 0.4s;  
  margin-top: -3px;
}

@media only screen and (max-width: 900px) {
  .tableBg {
    background: transparent;
    padding: 0px;
    border-radius: 50%;
    margin-top: 20px;
    margin-bottom: 15px;
}
  .tableBg2{
    background: transparent;
      padding:0px;
      border-radius: 50%;
      margin-top: 30px;
  }
  .meetingLink h6 {
    color: #fff;
    padding-left: 0px;
    font-size: 13px;
    position: inherit !important;
    cursor: pointer;
    padding-right: 20px;
}
  .leaveBtn {
    position: inherit;
    right: 0;
    bottom: 0;
    padding: 2px 15px !important;
    margin-left: 30px;
}
.itemDot1 img {
  width: 80px;
  background: transparent;
  border-radius: 0px;
  padding: 5px;
  margin-left: 5px;
  border: 0px solid #0b4aa1;
  transform: rotate(93deg);
}
.main-logo{
  width: 220px!important;
  margin: 0 auto;
} 
.ciclegraph .circle {
  position: absolute;
  top: 50%;
  left:40%;
  width: 70px;
  height: 70px;
  margin: calc(-70px / 2);
  /* background: #ffffff; */
  border-radius: 50%;
  /* border: 1px solid #00008c; */
  padding: 3px;
  cursor: pointer;
}
.col-xs-8{
  flex: 0 0 auto;
    width: 66.66666667%;
}
.col-xs-8{
  flex: 0 0 auto;
    width: 66.66666667% !important;
}
.col-xs-4{ 
    flex: 0 0 auto;
    width: 33.33333333% !important; 
}
.logout {
  text-align: right;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 15px;
}
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}
.empChairImg {
  cursor: pointer;
}
.text-left{
  text-align: left;
}
.rmTbFooter{
  margin: 0 auto !important;
  justify-content: center !important;
}
.rmBtn{
  margin: auto !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.headerBtn{
  margin: 0px !important;
  margin-left: auto !important;
    margin-top: 14px !important;
    outline: 0;
}
.btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow:none !important;
}
.moadlPadd{
  padding: 30px !important;
}
  .closeBtn {
    position: absolute;
    right: -10px;
    background: blue;
    color: #fff;
    top: -10px;
    border-radius: 50px;
    padding: 0px 7px; 
    cursor: pointer;
}
.lineHeight {
  line-height: 30px;
  font-size: 17px;
  padding-left: 20px;
}
.mt-60{
  margin-top: 60px !important;
}
.logout{
  text-align: right;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 20px;
}
.logout:hover{
  color: #02028b;
  cursor: pointer;
}
.showCursor{
  cursor: pointer;
}
.modal-dialog{
  padding: 15px;
}
.head-text{
  text-align: right;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 2px;
}
.hidden-lg{
  display: none;
}
.hidden-xs{
  display: block;
}
.room-head {
  color: #fff;
  position: absolute;
  right: 11px;
  top: 47px;
  font-size: 12px;
}
.modal-chromeTitle{
  position: absolute;
  right: 10px;
}
.removeTable {
  width: 100px;
  font-weight: 500;
  z-index: 999;
  position: absolute;
  /* left: 20px; */
  margin-left: 13.9%;
  margin-top: 10.4%;
  color: #000;
  background: #e7d0a359;
  font-size: 16px;
  padding: 0px 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  width: 106px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.removeTable2 {
    font-weight: 500;
    z-index: 999;
    position: absolute;
    /* left: 20px; */
    margin-left: 13.3%;
    margin-top: 9.9%;
    color: #000;
    background: #e7d0a359;
    font-size: 16px;
    padding: 0px 8px;
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.lock-icon{
  font-weight: 500;
    z-index: 999;
    position: absolute;
    /* left: 20px; */
    margin-left: 16%;
    margin-top: 8.7%;
    color: #222;
    background: #e7d0a359;
    font-size: 16px;
    padding: 1px 7px;
    border-radius: 50px;
    text-align: center;
}
.trashIcon{
  font-size: 12px;
    color: #d41414;
}
.radioInput{
  margin-left: 10px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.radioInput input{
  margin-left: 10px;
}
.lock-img {
  position: absolute;
    top: 43px;
    left: 43px;
    font-size: 13px;
    color: #ffffff;
    background: #2e3742;
    padding: 6px 7px;
    border-radius: 50px;
}
.lock-imgNone{
  display:none !important;
}
.joinTableInput2{
  width: 80% !important;
    float: left;
    margin: 0px;
    height: 35px;
}
@media(max-width:900px){
  .hidden-xs{
    display: none;
  }
  .hidden-lg{
    display: block;
  }
  .mobile-text{
    text-align: right;
    margin-bottom: 0px;
    margin-top: -7px !important;
  }
  .room-head {
    color: #fff;
    position: absolute;
    right: 11px;
    top: 58px;
    font-size: 12px;
}
.removeTable {
  font-weight: 500;
  z-index: 999;
  position: absolute;
  left: 22%;
  margin-top: 43.3%;
  color: #000;
  background: #e7d0a359;
  font-size: 18px;
  padding: 0px 8px;
  cursor: pointer;
  border-radius: 10px;
}
.fadeText {
  display: block;
  color: #e65454!important;
  opacity: 0;
  -webkit-animation: fade 3s linear;
  animation: fade 3s linear;
  position: absolute;
  left: 10%;
  top: 3px;
}
.lock-icon {
  font-weight: 500;
  z-index: 999;
  position: absolute;
  left: 29%;
  /* margin-left: 16%; */
  margin-top: 37%;
  color: #222;
  background: #e7d0a359;
  font-size: 16px;
  padding: 1px 7px;
  border-radius: 50px;
  text-align: center;
}
.Room{
  touch-action: pan-down;
}

}
.pb-30{
  padding-bottom: 30px;
}