.holderCircle {
    width: 270px;
    height: 270px;
    border-radius: 100%;
    margin: 0px auto;
    position: relative;
}

.dotCircle {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    left: 50px;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    z-index: 20;
}
.dotCircle a {
       display: block;
        width: 80px;
        height: 80px;
        position: absolute;
        background: transparent;
        color: #0b4b91;
        border-radius: 50px;
        text-align: center;
        line-height: 80px;
        font-size: 30px;
        z-index: 3;
        cursor: pointer;
        border: 0px solid #e6e6e6;
}
.dotCircle a .forActive {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
}
.dotCircle a .forActive::after {
    content: "";
    width: 5px;
    height: 5px;
    border: 3px solid #0b4b91;
    bottom: -31px;
    left: -14px;
    filter: blur(1px);
    position: absolute;
    border-radius: 100%;
}
.dotCircle a .forActive::before {
    content: "";
    width: 6px;
    height: 6px;
    filter: blur(5px);
    top: -15px;
    position: absolute;
    transform: rotate(-45deg);
    border: 6px solid #0b4b91;
    right: -39px;
}
.dotCircle a.active .forActive {
    display: block;
}
.round {
    position: absolute;
    left: 31px;
    top: 10px;
    width: 240px;
    height: 240px;
    border: 1px solid #636363;
    /* background: linear-gradient(45deg, #90b4ea, #dbd3c8); */
    box-shadow: 0px 2px 9px #000000;
    border-radius: 100%;
    /* -webkit-animation: rotation 100s infinite linear; */
}
.dotCircle a:hover,
.dotCircle a.active {
    color: #ffffff;
    transition: 0.5s; /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#7d4ac7+0,a733bb+100 */
    background: #7d4ac7; /* Old browsers */
    background: -moz-linear-gradient(left, #0b4b91 0%, #559de9 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #0b4b91 0%, #559de9 100%); /* Chrome10-25,Safari5.1-6 */
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7d4ac7', endColorstr='#a733bb', GradientType=1); /* IE6-9 */
    border: 0px solid #ffffff;
    -webkit-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, 0.13);
    transform: scale(1.2);
}
.dotCircle a {
    font-size: 40px;
}
.contentCircle {
   width: 54%;
    border-radius: 100%;
    color: #222222;
    position: relative;
    top: 36%;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 555;
}
.contentCircle .CirItem {
    border-radius: 100%;
    color: #222222;
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    transition: 0.5s;
    font-size: 15px;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    margin: auto;
    line-height: 250px;
}
.CirItem.active {
    z-index: 1;
    opacity: 1;
    transform: scale(1);
    transition: 0.5s;
}
.contentCircle .CirItem i {
    font-size: 180px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -90px;
    color: #000000;
    opacity: 0.1;
}
@media only screen and (min-width: 300px) and (max-width: 599px) {
    .holderCircle {
        width: 300px;
        height: 300px;
        margin: 110px auto;
    }
    .holderCircle::after {
        width: 100%;
        height: 100%;
    }
    .dotCircle {
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}
@media only screen and (min-width: 600px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
}
.title-box .title {
   font-weight: 600;
    letter-spacing: 1px;
    position: relative;
    z-index: -1;
    font-size: 19px;
}
.title-box span {
    text-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
    font-weight: 800;
    color: #02028B !important;
}
.title-box p {
    font-size: 13px;
    line-height: 22px;
}
.CirItem img{
   width: 196px;
    position: absolute;
    right: -1px;
    top: 17px;
    opacity: 0.1;
    z-index:-1;
}
.itemDot1 img {
    width: 80px;
    background: transparent;
    border-radius: 0px;
    padding: 5px;
    margin-left: 0px;
    border: 0px solid #0b4aa1;
    transform: rotate(93deg);
}
.itemDotImg img{
    width: 70px;
     background: #fff;
     border-radius: 50px;
     padding: 5px;
     border: 1px solid #0b4aa1;
 }

.itemDot2 img{
     width: 70px;
    background: #fff;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid #0b4aa1;
}
.itemDot3 img{
    width: 70px;
    background: #fff;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid #0b4aa1;
}
.itemDot4 img{
     width: 70px;
    background: #fff;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid #0b4aa1;
}
.itemDot5 img{
    width: 70px;
    background: #fff;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid #0b4aa1;
}
.itemDot6 img{
    width: 70px;
    background: #fff;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid #0b4aa1;
}
.itemDot img:nth-child(2) {
  display: none;  
}

.titleName {
font-size: 11px;
position: absolute;
left: 12px;
font-weight: 600;
background: #ffffff;
padding: 2px 3px;
color: blue;
}

.titleNameJoin {
    font-size: 13px;
    position: absolute;
    left: 26px;
    font-weight: 600;
    top: 0px;
    background: transparent;
    padding: 2px 8px !important;
    color: blue;
}
h2 {
    font-size: 28px;
    color: #123ebb;
    width: 100%;
    text-align: center;
    margin-top: 0px;
    font-weight: 600;
}
.modal-open .modal {
  z-index:9999;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    margin:0 auto;
    top: 30%;
    pointer-events: none;
}
.search-bar {
   border-radius: 20px;
    border: 1px solid #959595;
    padding: 4px 10px;
    width: 100%;
    margin-top: 12px;
}
.search-icon{
    background: #123ebb;
    padding: 5px 10px;
    border-radius: 18px;
    color: #fff;
    position: absolute;
    right: 14px;
    margin-top: 12px;
}
.cluster-btn {
    width: 100%;
    border-radius: 50px !important;
    margin-top: 12px;
    background-color:#02028B !important;
   border-color:#02028B  !important;
   color:#fff !important;
}
.cluster-btn:hover {
    width: 100%;
    border-radius: 50px !important;
    margin-top: 12px;
    background-color:#123ebb !important;
   border-color: #123ebb !important; 
   color:#fff !important;
}
.btn-primary:hover{
 background:#123ebb !important;
 border-color: #123ebb !important; 
}
.btn-primary{
 background:#123ebb !important;
 border-color: #123ebb !important; 
}
.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 14px !important;
    font-weight: 700;
    line-height: 1;
    color: #000 !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.createBtn{
    width: auto !important;
    margin-top: 0px !important;
}
.modal-title{
    width:100% !important;
}
 /* .emptyChair{
    z-index: -1;
}  */
#arrow {
    background-color: #e7d0a3;
    color: #fff;
    border-radius: 15px;
    padding: 0px;
    z-index: 999;
    height: 20px;
    max-width: 185px;
    position: relative;
    width: 90px;
    /* box-shadow: 0px 0px 4px #b7b7b7; */
    top: -13px;
    right:-25px;
}
#arrow:before {
    position: absolute;
    top: -10px;
    left: 20px;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #d8a76c;
}
#arrow2 {
    background-color: #ffffff;
    color: #fff;
    border-radius: 15px;
    padding: 0px;
    z-index: 999;
    height: 20px;
    max-width: 185px;
    position: relative;
    width: 90px;
    box-shadow: 0px 0px 4px #b7b7b7;
    top: 0px;
    left: 26px;
}
#arrow2:before {
    position: absolute;
    top: -10px;
    left: 20px;
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 10px solid #e1e1e1;
}
/* .emptyChair:hover{
    transition: 0.5s;     
    box-shadow: 0 30px 30px 0 rgb(0 0 0 / 13%);
    transform: scale(1.2);
} */


@media only screen and (max-width: 900px) {
    .holderCircle {
        width: 100%;
        height: 300px;
        margin: 0 auto;
        margin-top: 30px;
    }
    .round {
        position: absolute;
        left: 11%;
        top: 20px;
        width: 250px;
        height: 250px;
        border: 2px dotted #0b4b91;
        border-radius: 100%;
        /* -webkit-animation: rotation 100s infinite linear; */
    }
    .dotCircle a {
        font-size: 40px;
        margin-left: 5px;
    }
    #arrow {
    background-color: #e7d0a3;
    color: #fff;
    border-radius: 15px;
    padding: 0px;
    z-index: 999;
    height: 20px;
    max-width: 185px;
    position: relative;
    width: 90px;
    /* box-shadow: 0px 0px 4px #b7b7b7; */
    top: -13px;
}
   
}
.mb-40{
    margin-bottom: 60px;
}
.error{
    color: red;
}